var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    _vm._b(
      {
        attrs: {
          "form-action-method": _vm.formActionWindow,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          "is-form-submitting": _vm.formSubmitting,
          "page-path": _vm.pagePath,
          title: _vm.title,
          "title-icon": "mdi-file-table",
          "initial-width": "4",
        },
      },
      "asyent-window",
      _vm.$attrs,
      false
    ),
    [
      _c("asyent-form", {
        ref: _vm.formRefAE,
        attrs: {
          "view-mode": _vm.viewMode,
          "data-error": _vm.dataError,
          "data-loading": _vm.dataLoading,
          errors: _vm.errors,
          "form-action-method": _vm.formActionMethod,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          "is-form-submitting": _vm.formSubmitting,
          readonly: _vm.isReadonly,
          reference: _vm.formRef,
          success: _vm.success,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _vm.rop !== "new"
                          ? _c("asyent-form-info", {
                              attrs: { "no-title": "", "no-maximize": "" },
                              model: {
                                value: _vm.formData,
                                callback: function ($$v) {
                                  _vm.formData = $$v
                                },
                                expression: "formData",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { title: "General", expandable: "" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value:
                                          _vm.formDataO["carrierBookingNumber"],
                                        label: "Tracking Number",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _vm.getEnv === "kh"
                                  ? _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", md: "4", lg: "3" },
                                      },
                                      [
                                        _c("asyent-form-display", {
                                          attrs: {
                                            value:
                                              _vm.formDataF[
                                                "postalConsignmentSystemCategory"
                                              ],
                                            label:
                                              "Postal Consignment System Category",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.getEnv === "kh"
                                  ? _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", md: "4", lg: "3" },
                                      },
                                      [
                                        _c("asyent-form-catalog", {
                                          attrs: {
                                            sortable: "",
                                            "view-mode": _vm.viewMode,
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            "service-name": "catalog",
                                            catalog:
                                              "CatalogPostalConsignmentCategory",
                                            "show-item-code": "",
                                            label:
                                              "New Postal Consignment Category",
                                            "item-value": "code",
                                            "item-text": "description",
                                            name: "consignmentType",
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF[
                                                "postalConsignmentOfficerCategory"
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF,
                                                "postalConsignmentOfficerCategory",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['postalConsignmentOfficerCategory']",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { extended: "", expandable: "" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12", lg: "12" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          flat: "",
                                          color: "bgWhite",
                                          title: "Consignee",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "2",
                                                          lg: "2",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "asyent-form-finder",
                                                          {
                                                            attrs: {
                                                              "working-date":
                                                                _vm.workingDate,
                                                              "view-mode":
                                                                _vm.viewMode,
                                                              "form-errors":
                                                                _vm.formErrors,
                                                              "form-rules":
                                                                _vm.formFieldsRules,
                                                              "is-disabled-func":
                                                                _vm.isDisabled,
                                                              "service-name":
                                                                "catalog",
                                                              catalog:
                                                                "CatalogCompany",
                                                              "data-fields":
                                                                _vm.dataFields,
                                                              props: {},
                                                              label: "TIN",
                                                              "item-value":
                                                                "code",
                                                              "item-text":
                                                                "companyName",
                                                              name: "CatalogCompany",
                                                              "as-text": "",
                                                              "is-not-cacheable": true,
                                                              "pre-poplated": false,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.formDataF[
                                                                  "consignee"
                                                                ]["id"],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formDataF[
                                                                      "consignee"
                                                                    ],
                                                                    "id",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "formDataF['consignee']['id']",
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12", lg: "12" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          flat: "",
                                          color: "bgWhite",
                                          title: "Items",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          _vm._l(
                                            _vm.formDataF["consignmentItems"],
                                            function (item, index) {
                                              return _c(
                                                "v-col",
                                                {
                                                  key: index,
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "3" },
                                                        },
                                                        [
                                                          _c(
                                                            "asyent-form-text",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  _vm.formDataF[
                                                                    "consignmentItems"
                                                                  ][index][
                                                                    "itemNumber"
                                                                  ],
                                                                label:
                                                                  _vm.ae$lng(
                                                                    "itemNumber"
                                                                  ),
                                                                "is-disabled-func":
                                                                  function () {
                                                                    return true
                                                                  },
                                                                name: "consignmentItems.itemNumber",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "3" },
                                                        },
                                                        [
                                                          _c(
                                                            "asyent-form-text",
                                                            {
                                                              attrs: {
                                                                "view-mode":
                                                                  _vm.viewMode,
                                                                "form-errors":
                                                                  _vm.formErrors,
                                                                "form-rules":
                                                                  _vm.formFieldsRules,
                                                                "is-disabled-func":
                                                                  function () {
                                                                    return true
                                                                  },
                                                                label:
                                                                  _vm.ae$lng(
                                                                    "HS Code (Original)"
                                                                  ),
                                                                name: "consignmentItems.hsCode",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.formDataF[
                                                                    "consignmentItems"
                                                                  ][index][
                                                                    "hsCode"
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .formDataF[
                                                                        "consignmentItems"
                                                                      ][index],
                                                                      "hsCode",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "formDataF['consignmentItems'][index]['hsCode']",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "3" },
                                                        },
                                                        [
                                                          _c(
                                                            "asyent-form-text",
                                                            {
                                                              attrs: {
                                                                "view-mode":
                                                                  _vm.viewMode,
                                                                "form-errors":
                                                                  _vm.formErrors,
                                                                "form-rules":
                                                                  _vm.formFieldsRules,
                                                                "is-disabled-func":
                                                                  _vm.isDisabled,
                                                                label:
                                                                  _vm.ae$lng(
                                                                    "HS Code (Updated)"
                                                                  ),
                                                                name: "consignmentItems.commodityCode",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.formDataF[
                                                                    "consignmentItems"
                                                                  ][index][
                                                                    "commodityCode"
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .formDataF[
                                                                        "consignmentItems"
                                                                      ][index],
                                                                      "commodityCode",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "formDataF['consignmentItems'][index]['commodityCode']",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("asyent-toc"),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.formValid,
          callback: function ($$v) {
            _vm.formValid = $$v
          },
          expression: "formValid",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }