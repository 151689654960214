var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    {
      attrs: {
        "header-actions": _vm.headerActions,
        "page-path": _vm.ae$pagePath,
        title: _vm.ae$title,
        "toolbar-actions": _vm.toolbarActions,
        widget: _vm.widget,
        "title-icon": "mdi-package",
        "initial-width": "4",
      },
      on: {
        "toolbar-action": function ($event) {
          return _vm.toolbarMethod($event)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "header-filter",
          fn: function () {
            return [
              _c("asyent-table-filter", {
                attrs: { "all-headers": _vm.headers },
                model: {
                  value: _vm.viewColumns,
                  callback: function ($$v) {
                    _vm.viewColumns = $$v
                  },
                  expression: "viewColumns",
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("asyent-search", {
        attrs: {
          "filter-dialog": _vm.filterDialog,
          filters: _vm.filters,
          headers: _vm.headers,
          "displayed-columns": _vm.viewColumns,
          "filter-columns": "",
          "reload-counter": _vm.reloadCounter,
          "filter-counter": _vm.filterCounter,
          "row-actions": _vm.rowActions,
          "row-method": _vm.rowMethod,
          "search-method": _vm.search,
          criteria: _vm.criteria,
          "sort-by": _vm.sortColumns,
          "sort-desc": _vm.sortOrder,
          "unique-key": "id",
        },
        on: {
          "update:sortBy": function ($event) {
            _vm.sortColumns = $event
          },
          "update:sort-by": function ($event) {
            _vm.sortColumns = $event
          },
          "update:sortDesc": function ($event) {
            _vm.sortOrder = $event
          },
          "update:sort-desc": function ($event) {
            _vm.sortOrder = $event
          },
          "filter-dialog": function ($event) {
            _vm.filterDialog = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "item.arrivalStatus",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "v-chip",
                  {
                    attrs: {
                      label: "",
                      color: _vm.arrivalStatusColor(item.arrivalStatus),
                      small: "",
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.ae$lng(
                              item.arrivalStatus
                                ? item.arrivalStatus
                                : "NOT_ARRIVED"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "item.papSystemRiskLevel",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "v-chip",
                  {
                    attrs: {
                      label: "",
                      color: _vm.showRiskAsColor(item.papSystemRiskLevel),
                      small: "",
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.ae$lng(
                              item.papSystemRiskLevel
                                ? item.papSystemRiskLevel
                                : "NOT_AVAILABLE"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "item.customsAction",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(item.customsAction ? "Running" : "Expired") +
                      " "
                  ),
                ]),
              ]
            },
          },
          {
            key: "item.manifestedGrossWeight",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("span", [
                  _vm._v(" " + _vm._s(item.manifestedGrossWeight) + " "),
                ]),
              ]
            },
          },
          {
            key: "item.totalUsdValue",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("span", [_vm._v(" " + _vm._s(item.totalUsdValue) + " ")]),
              ]
            },
          },
        ]),
      }),
      _c("div", { staticClass: "bottom-space" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }