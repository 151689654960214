var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    _vm._b(
      {
        attrs: {
          "form-action-method": _vm.formActionWindow,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          "is-form-submitting": _vm.formSubmitting,
          "page-path": _vm.pagePath,
          title: _vm.title,
          "title-icon": "mdi-file-table",
          "initial-width": "4",
        },
      },
      "asyent-window",
      _vm.$attrs,
      false
    ),
    [
      _c("asyent-form", {
        ref: _vm.formRefAE,
        attrs: {
          "view-mode": _vm.viewMode,
          "data-error": _vm.dataError,
          "data-loading": _vm.dataLoading,
          errors: _vm.errors,
          "form-action-method": _vm.formActionMethod,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          "is-form-submitting": _vm.formSubmitting,
          readonly: _vm.isReadonly,
          reference: _vm.formRef,
          success: _vm.success,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _vm.rop !== "new"
                          ? _c("asyent-form-info", {
                              attrs: { "no-title": "", "no-maximize": "" },
                              model: {
                                value: _vm.formData,
                                callback: function ($$v) {
                                  _vm.formData = $$v
                                },
                                expression: "formData",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { title: "General", expandable: "" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: _vm.formDataF["providerName"],
                                        label: "Provider",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value:
                                          _vm.formDataF["providerObjectId"],
                                        label: "Provider Consignment ID",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: [
                                          _vm.formDataF["totalValueAmount"],
                                          _vm.formDataF["consignmentCurrency"],
                                        ],
                                        label: "Total Value",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: [
                                          _vm.formDataF[
                                            "manifestedGrossWeight"
                                          ]["value"],
                                          _vm.formDataF[
                                            "manifestedGrossWeight"
                                          ]["unit"],
                                        ],
                                        label: "Manifested Gross Weight",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: _vm.formDataF["arrivalStatus"],
                                        label: "Arrival Status",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: _vm.formDataF["itemsNumber"],
                                        label: "Number of Items",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: _vm.customsActionDisplay(
                                          _vm.formDataF["customsAction"]
                                        ),
                                        label:
                                          "Customs\n                Action",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: _vm.widthWithSad("postal"),
                                          md: _vm.widthWithSad("postal"),
                                        },
                                      },
                                      [
                                        _c(
                                          "asyent-card",
                                          {
                                            attrs: {
                                              title: "Postal",
                                              flat: "",
                                              "border-color": 2,
                                              "with-border": "",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "4",
                                                              lg: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    _vm
                                                                      .formDataF[
                                                                      "carrierBookingNumber"
                                                                    ],
                                                                  label:
                                                                    "Tracking Number",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "4",
                                                              lg: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value: [
                                                                    _vm
                                                                      .formDataF[
                                                                      "originatorId"
                                                                    ],
                                                                    _vm
                                                                      .formDataF[
                                                                      "originatorName"
                                                                    ],
                                                                  ],
                                                                  label:
                                                                    "Originator",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "4",
                                                              lg: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    _vm
                                                                      .formDataF[
                                                                      "idCargoVoyage"
                                                                    ][
                                                                      "departureDate"
                                                                    ],
                                                                  "as-date": "",
                                                                  label:
                                                                    "Posting Date",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "4",
                                                              lg: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-chip",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "success",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .formDataF[
                                                                      "type"
                                                                    ]
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "4",
                                                              lg: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value: [
                                                                    _vm
                                                                      .formDataF[
                                                                      "loadingPlace"
                                                                    ]["code"],
                                                                    _vm
                                                                      .formDataF[
                                                                      "loadingPlace"
                                                                    ]["name"],
                                                                    _vm
                                                                      .formDataF[
                                                                      "loadingPlace"
                                                                    ][
                                                                      "countryName"
                                                                    ],
                                                                  ],
                                                                  label:
                                                                    "Loading Place",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "4",
                                                              lg: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value: [
                                                                    _vm
                                                                      .formDataF[
                                                                      "dischargePlace"
                                                                    ]["code"],
                                                                    _vm
                                                                      .formDataF[
                                                                      "dischargePlace"
                                                                    ]["name"],
                                                                    _vm
                                                                      .formDataF[
                                                                      "dischargePlace"
                                                                    ][
                                                                      "countryName"
                                                                    ],
                                                                  ],
                                                                  label:
                                                                    "Discharge Place",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "4",
                                                              lg: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value: [
                                                                    _vm
                                                                      .formDataF[
                                                                      "flow"
                                                                    ],
                                                                  ],
                                                                  label:
                                                                    _vm.ae$lng(
                                                                      "flow"
                                                                    ),
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "4",
                                                              lg: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value: [
                                                                    _vm
                                                                      .formDataF[
                                                                      "nature"
                                                                    ],
                                                                  ],
                                                                  label:
                                                                    "Nature",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("v-row"),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              title: "Timer",
                              flat: "",
                              "border-color": 2,
                            },
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: _vm.ae$formatDateTime(
                                          _vm.formDataF["consignmentTimers"][
                                            "createdTime"
                                          ]
                                        ),
                                        label: "Created Time",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: _vm.ae$formatDateTime(
                                          _vm.formDataF["consignmentTimers"][
                                            "plannedExecutionTime"
                                          ]
                                        ),
                                        label: "Planned Execution Time",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "4", md: "4", lg: "4" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "view-mode": _vm.viewMode,
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: _vm.props,
                                        label: "Requested Delay (mins)",
                                        name: "lastRequestedDelay(mins)",
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF["consignmentTimers"][
                                            "lastRequestedDelay"
                                          ],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF["consignmentTimers"],
                                            "lastRequestedDelay",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['consignmentTimers']['lastRequestedDelay']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("asyent-toc"),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.formValid,
          callback: function ($$v) {
            _vm.formValid = $$v
          },
          expression: "formValid",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }